<template>
  <v-container fluid>
    <v-stepper :value="currentStep.number">
      <v-row no-gutters>
        <v-col class="d-flex align-center">
          <template v-for="step in stepList">
            <v-divider v-if="step.number !== 1" :key="`divider-${step.name}`" />
            <v-stepper-step
              :key="`step-${step.name}`"
              :step="step.number"
              :complete="step.number < currentStep.number"
            >
              <span v-text="$t(`criticalchain.steps.${step.name}`)" />
            </v-stepper-step>
          </template>
        </v-col>
      </v-row>

      <v-container fluid class="pt-0">
        <router-view :current-player="currentPlayer" />
      </v-container>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  name: 'PlayingContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  computed: {
    stepList () {
      return [
        {
          name: 'CriticalChainRemoveMultitask',
          number: 1,
        },
        {
          name: 'CriticalChainSelectCriticalChain',
          number: 2,
        },
        {
          name: 'CriticalChainDefineBufferSize',
          number: 3,
        },
      ]
    },
    currentStep () {
      return this.stepList.find(step => step.name === this.$route.name)
        || { name: 'CriticalChainFinished', number: 4 }
    },
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
  },
}
</script>
